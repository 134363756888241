export default {

  moveToNextQuestion ({ state, commit, getters, dispatch }) {
    const currentQuestion = state.currentQuestion
    if (!state.visitedQuestions.some(question => question.id === currentQuestion.id)) {
      commit('addVisitedQuestion', currentQuestion)
    }

    // check jumps
    let nextQuestionId
    if (currentQuestion.jumps.length) {
      for (const jump of currentQuestion.jumps) {
        const value = state.reportStatement[currentQuestion.key]
        let mustJump = false

        if (jump.type === 'IS') {
          mustJump = currentQuestion.type === 'MULTI_SELECT' ? value.includes(jump.value) : value === jump.value
        } else {
          mustJump = currentQuestion.type === 'MULTI_SELECT' ? !value.includes(jump.value) : value !== jump.value
        }

        if (mustJump) {
          nextQuestionId = jump.targetQuestionId
          break
        }
      }
    }

    if (nextQuestionId) {
      const question = getters.questions.find(question => question.id === nextQuestionId)
      dispatch('setCurrentQuestionTracking', question)
      return
    }

    // check default jump
    if (currentQuestion.defaultJump) {
      const question = getters.questions.find(question => question.id === currentQuestion.defaultJump)
      dispatch('setCurrentQuestionTracking', question)
      return
    }

    // next in regular process
    const index = getters.questions.findIndex(question => question.id === currentQuestion.id)
    const nextQuestion = getters.questions[index + 1]
    if (nextQuestion) {
      dispatch('setCurrentQuestionTracking', nextQuestion)
    } else {
      dispatch('setQuestionIndexTracking', 'SUMMARY')
    }
  },

  moveToPreviousQuestion ({ state, commit, dispatch }) {
    const lastQuestion = state.visitedQuestions[state.visitedQuestions.length - 1]
    if (lastQuestion) {
      commit('popLastVisitedQuestion')
      dispatch('setCurrentQuestionTracking', lastQuestion)
    } else {
      dispatch('setQuestionIndexTracking', 'CATEGORY')
    }
  },

  async loadCaptchaProperties ({ dispatch, commit }) {
    const response = await dispatch('GET', {
      url: '/rest/report/captcha-properties'
    })
    const captcha = await response.json()
    captcha.siteKey = atob(captcha.siteKey)
    commit('setCaptcha', captcha)
  },

  setSessionExpiredTracking({ commit, dispatch }, expired) {
    commit('setSessionExpired', expired)
    if (expired) {
      dispatch('createTracking', {index: 'SESSION_EXPIRED'})
    }
  },

  setCurrentQuestionTracking({ commit, dispatch, state }, question) {
    commit('setCurrentQuestion', question)
    dispatch('createTracking', {index: 'QUESTIONNAIRE', question_key: question.key, question_index: state.visitedQuestions.length + 1})
  },

  setQuestionIndexTracking({ commit, dispatch }, index) {
    commit('setQuestionIndex', index)
    if (index !== 'QUESTIONNAIRE') {
      dispatch('createTracking', {index})
    }
  },

  async createTracking({ dispatch }, trackingPage) {
    await dispatch('POST', {
      url: '/rest/report/status',
      body: JSON.stringify(trackingPage),
    })
  },

}
