<template>
  <v-navigation-drawer
    :disable-resize-watcher="true"
    :disable-route-watcher="true"
    :mobile-breakpoint="0"
    :stateless="true"
    :touchless="true"
    :rail="miniVariant"
    app
    class="navigation elevation-5"
    permanent
    width="256"
    :mini-variant="miniVariant"
    @transitionend="navTranistioned($event)"
    style="z-index: 100"
  >
    <div v-if="!userData.organization_id || currentUserOrganization">
      <NavigationHeader :is-mini-variant="isMiniVariant" @setMiniVariant="setMiniVariant" />
      <NavigationListAccount />
      <NavigationListReports />
      <NavigationListSettings />
      <NavigationListKnowledge />
    </div>

    <template v-slot:append>
      <v-list-item class="test-features" v-if="!miniVariant" title="">
        <h3 @click="$emit('showMailTriggerDialog')" v-if="showMailTrigger">{{ $t('mailTrigger.toggle') }}</h3>
      </v-list-item>

      <v-list-item v-if="isGlobalUser" :style='miniVariant?"padding:0px;":""' title="">
        <LanguageSelection
          :miniVariant='miniVariant'
          :show-all="true"
          @update:modelValue="language => changeUserLanguage({ language, userId: userData.id })"
          style="max-width: none !important;"
        />
      </v-list-item>

      <v-divider></v-divider>

      <NavigationLogout :is-mini-variant="isMiniVariant" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import LanguageSelection from './LanguageSelection'
import NavigationLogout from './navigation/NavigationLogout'
import NavigationListSettings from './navigation/NavigationListSettings'
import NavigationListKnowledge from './navigation/NavigationListKnowledge'
import NavigationListReports from './navigation/NavigationListReports'
import NavigationListAccount from './navigation/NavigationListAccount'
import NavigationHeader from './navigation/NavigationHeader'

export default {
  name: 'Navigation',
  components: {
    NavigationHeader,
    NavigationListAccount,
    NavigationListReports,
    NavigationListKnowledge,
    NavigationListSettings,
    NavigationLogout,
    LanguageSelection
  },
  props: {
    showMailTrigger: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMiniVariant: false
    }
  },
  async created () {
    if (localStorage.miniVariant === 'true') {
      this.setMiniVariant(true)
    }

    if (this.canNavigateQuestionnaireCustomizations) {
      this.loadQuestionnaireTemplates()
    }

    this.doesPricingPlanExpire()
  },
  computed: {
    ...mapState({
      userData: state => state.userData,
      ownAndChildOrganizations: state => state.ownAndChildOrganizations,
      miniVariant: state => state.miniVariant
    }),
    ...mapGetters([
      'canNavigateQuestionnaireCustomizations',
      'isGlobalUser',
      'currentUserOrganization'
    ])
  },
  methods: {
    ...mapActions(['changeUserLanguage', 'doesPricingPlanExpire', 'loadQuestionnaireTemplates']),
    ...mapMutations(['setMiniVariant']),
    navTranistioned (ev) {
      this.isMiniVariant = this.miniVariant
    }
  },
  watch: {
    miniVariant (val) {
      localStorage.miniVariant = val
    },
    async ownAndChildOrganizations () {
      if (this.canNavigateQuestionnaireCustomizations) {
        this.loadQuestionnaireTemplates()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
:deep(hr) {
  border-color: var(--border-gray) !important;
}

:deep(.v-subheader) {
  height: 16px !important;
  padding-left: 16px !important;
  margin-top: 15px;
}

:deep(.v-list-item) {
  * {
    color: var(--title-text-color);
  }

  .v-list-item__action {
    margin: 0;
  }

  .v-btn {
    padding: 0 6px !important;
  }

  &:before {
    transition: none !important;
  }
}

:deep(.v-list-item-title) {
  font-size: 14px !important;
  font-weight: bold !important;
}

:deep(.v-list-item--active) {

  * {
    color: var(--accent-color) !important;
  }

  &:before {
    width: 5px;
    height: 25px;
    background-color: var(--accent-color);
    content: '';
    position: absolute;
    left: 5px;
    opacity: 1 !important;
    border-radius: 3px;
  }
}

:deep(.v-badge__badge.error) {
  color: #fff;
}

:deep(.nav-separator)
{
  min-height: 1px;
  max-height: 1px;
  background: var(--border-gray);
}

</style>
