<template>
  <v-navigation-drawer
    app
    permanent
    color="primary"
    class="elevation-1"
    dark
    :disable-resize-watcher="true"
    :touchless="true"
    :disable-route-watcher="true"
    :mobile-breakpoint="0"
    :stateless="true"
    :style="showSpecialImageDe || showSpecialImageIt || showSpecialImageFr || showSpecialImageEs ? 'border: none' : ''"
    style="width: 400px"
  >
    <template v-slot:image>
      <v-img
        v-if="showSpecialImageDe"
        src="../assets/left-menu-bg-de.png"
        width="100%"
        height="100%"
        alt="left menu background"/>
      <v-img
        v-if="showSpecialImageIt"
        src="../assets/left-menu-bg-it.png"
        width="100%"
        height="100%"
        alt="left menu background"/>
      <v-img
        v-if="showSpecialImageEs"
        src="../assets/left-menu-bg-es.png"
        width="100%"
        height="100%"
        alt="left menu background"/>
      <v-img
        v-if="showSpecialImageFr"
        src="../assets/left-menu-bg-fr.png"
        width="100%"
        height="100%"
        alt="left menu background"/>
      <v-img src="../assets/left-menu-bg.png" width="100%" height="100%" style="filter: brightness(20%)"
               position="35% 100%" alt="left menu background"/>
    </template>
    <div style="display: grid; height: 100%">
      <Stepper v-if="['SetupMfa', 'SetupLogin', 'SetupPasswordReset', 'SetupBranding', 'SetupLanguageReset'].includes($route.name)" v-model="signupStepper" :items="setupStepperItems" >
      </Stepper>

      <Stepper
        v-else-if="(['Signup', 'SignupPlan', 'SignupConfirmation', 'Login', 'SignupPayment'].includes($route.name) || (userData && userData.requires_password_change) || (userData && !userData.setup_completed)) && ((userData && userData.requires_password_change) || (userData && !userData.setup_completed) || $route.query.passwordReset)"
        v-model="signupStepper"
        :items="signupStepperItemsPasswordChange" >
      </Stepper>

      <Stepper
        v-else-if="(['Signup', 'SignupPlan', 'SignupConfirmation', 'Login', 'SignupPayment'].includes($route.name) || (userData && userData.requires_password_change) || (userData && !userData.setup_completed)) && (!['Login'].includes($route.name))"
        v-model="signupStepper"
        :items="signupStepperItems" >
      </Stepper>
    </div>
  </v-navigation-drawer>
</template>

<script>

import { mapState, mapMutations, mapGetters } from 'vuex';
import Stepper from '@/components/Stepper.vue';

export default {
  name: 'LoginNavigation',
  components: {Stepper},
  data () {
    return {
      stepper: 1
    }
  },
  computed: {
    ...mapState({
      currentOrganizationId: state => state.currentOrganizationId,
      organizations: state => state.organizations,
      userData: state => state.userData,
      currentUserOrganization: state => state.currentUserOrganization
    }),
    ...mapGetters(['isOrganizationAdmin', 'isOrganizationSetupWithBranding', 'organizationHasSignInMethodExternal']),
    signupStepper () {
      switch (this.$route.name) {
        case 'Signup': return 1
        case 'SignupPlan': return 2
        case 'SignupPayment': return 3
        case 'SignupConfirmation': return 4
        case 'MfaSetup': return 4
        case 'SetupMfa': return 4
        case 'SetupLogin': return 1
        case 'SetupLanguageReset': return 2
        case 'SetupPasswordReset': return 3
        case 'SetupBranding': return (this.useSignInMethodExternal ? 3 : 5)
        case 'LanguageReset': return 2
        // eslint-disable-next-line camelcase
        case 'PasswordReset': return !this.userData?.setup_completed ? 3 : 2
      }

      return 1
    },
    signupStepperItemsPasswordChange () {
      return [
        {
          title:'setup.navigation.login',
          isNotShown: (this.$route.name === 'Login' && this.$route.query.passwordReset)
        },
        {
          title:'setup.navigation.language',
          isNotShown: this.userData.setup_completed
        },
        {
          title:'signup.welcome.passwordReset',
          isNotShown: this.useSignInMethodExternal
        },
        {
          title:'signup.welcome.mfaSetup',
          isNotShown: this.userData.setup_completed || this.useSignInMethodExternal
        }
      ]
    },
    signupStepperItems () {
      return [
        {
          title:'signup.navigation.company'
        },
        {
          title:'signup.navigation.pricingPlan'
        },
        {
          title:'signup.navigation.payment'
        },
        {
          title:'signup.navigation.confirmation'
        }

      ]
    },
    setupStepperItems () {
      return [
        {
          success: this.signupStepper > 1,
          title: 'setup.navigation.login'
        },
        {
          success: this.signupStepper > 2,
          title: 'setup.navigation.language'
        },
        {
          title: 'setup.navigation.setNewPassword',
          success: this.signupStepper > 3,
          isNotShown: this.useSignInMethodExternal
        },
        {
          title: 'signup.welcome.mfaSetup',
          success: this.signupStepper > 4,
          isNotShown: this.useSignInMethodExternal
        },
        {
          title: 'setup.navigation.corporateIdentity',
          success: this.signupStepper > 5,
          isNotShown: !this.isOrganizationSetupWithBranding
        }
      ]
    },
    showSpecialImageDe () {
      return this.currentOrganizationId === 'b9aa353c-c6fd-46d5-b0fb-c8560a1de391' || this.currentOrganizationId === '3b0a6263-63c4-4755-aa6d-3d1a421992af'
    },
    showSpecialImageIt () {
      return this.currentOrganizationId === 'cad2e0c6-ff86-4012-8aa0-3771e73b0c7c'
    },
    showSpecialImageFr () {
      return this.currentOrganizationId === '5a7657a7-62d2-42e9-88fa-28cf40a0bab2'
    },
    showSpecialImageEs () {
      return this.currentOrganizationId === '36497bfb-11da-4c4a-a233-10ffb1d5a8e8'
    },
    currentOrganization () {
      return this.organizations.find(org => org.id === this.currentOrganizationId)
    },
    useSignInMethodExternal () {
      return this.organizationHasSignInMethodExternal(this.currentOrganizationId)
    },

    // TODO refactor this into a getter, logo file name exists in several places implemented exactly this way
    logoFileName () {
      const organization = this.organizations.find(organization => organization.id === this.currentOrganizationId)
      return organization && organization.logo_filename ? '_' + organization.logo_filename : '_logo'
    }
  },
  methods: {
    ...mapMutations(['setSignupStepper'])
  }

}
</script>

<style scoped lang="scss">

.primary-color{
  background: var(--v-primary-base);
  color: var(--v-primary-base)
}
  .logo-img {
    height: 55px;
    max-width: 100%;
    margin-top: 20px;
    justify-self: center;
    padding: 5px;
    background-color: white;
    border-radius: 3px
  }

  :deep(.v-stepper__step) {
    .v-stepper__step__step {
      width: 40px;
      height: 40px;
      background-color: transparent !important;
      color: #fff !important;
      font-size: 20px;
      line-height: 20px;
      padding-top: 3px;
      border: 1px solid #fff !important;
    }

  }

  :deep(.v-stepper__step--active) {
    .v-stepper__step__step {
      border: 2px solid var(--accent-color) !important;
      color: var(--accent-color) !important;
    }

    .v-stepper__label {
      color: var(--accent-color) !important;
      text-shadow: none!important;
      font-size: 20px!important;
    }
  }

  :deep(.v-stepper__step--complete) {
    .v-stepper__step__step {
      border: 2px solid var(--accent-color) !important;
    }

    i {
      background: var(--accent-color);
      border-radius: 12px;
      width: 24px;
      height: 24px;
      margin-top: -2px;
    }

    .v-stepper__label {
      color: var(--accent-color) !important;
    }
  }

.image-container {
  position: relative;
  width: 100%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; /* Lower value so it's behind */
}

.foreground-image {
  position: relative;
  width: 100%;
  z-index: 2; /* Higher value so it's in front */
}

</style>
