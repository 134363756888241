
export default {

  toggleOrganizationFilter (state, { filter, value }) {
    const isPresent = state.organizationsTableOptions.filter[filter].includes(value)

    if (isPresent) {
      state.organizationsTableOptions.filter[filter] = state.organizationsTableOptions.filter[filter].filter(_value => _value !== value)
    } else {
      state.organizationsTableOptions.filter[filter] = [...state.organizationsTableOptions.filter[filter], value]
    }
  },

  setOrganizationsTableOptions (state, {key, value}) {
    state.organizationsTableOptions[key] = value
  },

  reloadOrganizationLogos (state, organizations) {
    for (const organization of state.organizations) {
      const newOrganization = organizations.find(org => org.id === organization.id)
      if (newOrganization) {
        organization.logo_filename = newOrganization.logo_filename
        organization.favicon_filename = newOrganization.favicon_filename
      }
    }
  },

  setShowLastReportClosed (state, showLastReportClosed) {
    state.showLastReportClosed = showLastReportClosed
  },
}
