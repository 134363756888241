import { SESSION_LENGTH_MINUTES } from '@/constants'

export default {

  setUserData (state, userData) {
    state.userData = userData

    this.commit("setSessionExpiredTimeoutId")
  },

  setSessionExpiredTimeoutId (state) {
    if (state.sessionExpiredTimeoutId) {
      clearTimeout(state.sessionExpiredTimeoutId)
    }

    // logged-in user session has authToken & userData,
    // whb session has authToken only
    if (!state.userData || !state.authToken) {
      return
    }

    // 10 seconds  is added to allow for a graceful keepalive without flickering between 15m
    state.sessionExpiredTimeoutId = setTimeout(async () => {
      try {
        // Check if the session is active, as the user might be staying active in another tab.
        const responseSession = await this.dispatch('refreshSession')
        if (!responseSession.ok) {
          throw new Error('Session refresh failed.');
        } else {
          this.commit("setSessionExpiredTimeoutId")
        }
      } catch (e) {
        this.commit('setSessionExpired', true);
        this.dispatch('logout')
      }
    }, SESSION_LENGTH_MINUTES * 60 * 1000 + 10000);
  },

  setAuthToken (state, authToken) {
    state.authToken = authToken
  },

  setShowWelcomePopup (state, showWelcomePopup) {
    state.showWelcomePopup = showWelcomePopup
  },

  setSetupCompleted (state, setupCompleted) {
    state.userData.setup_completed = setupCompleted
  },

  setSessionExpired (state, expired) {
    // expired window will only open when session was not expired yet, but will be set now
    state.sessionExpired = expired
  }
}
