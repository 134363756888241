import apollo from '../../apollo'
import gql from 'graphql-tag'
import { internalNoteFields } from '../graphqlFragments'

export default {
  async addInternalNote ({ state, dispatch }, note) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/add-internal-note`,
      body: note
    })
  },

  async deleteNote ({ state, dispatch }, note) {
    await dispatch('DELETE', {
      url: `/rest/report/${state.currentReport.id}/notes/${note.id}`
    })
  },

  async updateNote ({ state, dispatch }, note) {
    note.edit = false
    note.text = note.newText
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/${note.id}`,
      body: note.newText
    })
  },

  async pinNote({ state, dispatch }, note) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/notes/${note.id}/pin`
    })
  },

  async unpinNote({ state, dispatch }, note) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/notes/${note.id}/unpin`
    })
  },

  subscribeToNoteChanges ({ state, commit }) {
    if (state.noteSubscription) {
      state.noteSubscription.unsubscribe()
    }

    const observer = apollo(state).subscribe({
      query: gql`
        subscription internal_note($reportId: uuid) {
          internal_note(where: {report_id: {_eq: $reportId}}) {
            ${internalNoteFields(state.userData)}
          }
        }
      `,
      variables: {
        reportId: state.currentReportId
      }
    })

    const subscription = observer.subscribe({
      next (data) {
        if (!state.currentReport) {
          return
        }
        const invalidId = data.data.internal_note.some(internalNote => internalNote.report_id !== state.currentReportId)

        if (invalidId) {
          return
        }

        commit('setInternalNotes', data.data.internal_note)
      },
      error (error) {
        console.error('subscription error', error)
      }
    })

    commit('setNoteSubscription', subscription)
  }

}
